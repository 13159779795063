import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import DropdownMenu from "./DropdownMenu"
import MobileDropdownMenu from "./MobileDropDownMenu"
import { MdClose, MdMenu } from "react-icons/md"

// https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks

// default to empty strings because I may use it for other purposes (that don't use these props)
const MobileNav = ({
  handle = null,
  itemKey = "",
  name = "",
  dropdown = null,
  dropdownWrap = false,
  className = "",
}) => {
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const dropDownRef = useRef(null)

  name = (
    <div className="flex w-full mx-4 items-center justify-between ">
      {!dropDownVisible ? <MdMenu size="3rem" /> : <MdClose size="3rem" />}
    </div>
  )

  const navLinkClass = `bg-white hover:bg-none border-2 border-b-0 border-white rounded-t-3xl text-shibBlue text-center group-hover:bg-shibBlue group-hover:text-white  xl:text-base h-full grid items-center py-3 uppercase tracking-wide font-bold ${className} `
  // console.log(handle)

  const handleDropDownClick = () => {
    if (!dropdown) {
      return
    } else {
      setDropDownVisible(!dropDownVisible)
    }
  }

  useEffect(() => {
    const pageClickEvent = e => {
      if (
        dropDownRef.current !== null &&
        !dropDownRef.current.contains(e.target)
      ) {
        setDropDownVisible(!dropDownVisible)
      }
    }
    if (dropDownVisible) {
      window.addEventListener("click", pageClickEvent)
    }
    return () => {
      window.removeEventListener("click", pageClickEvent)
    }
  }, [dropDownVisible])

  const MobileDropDownMenu = () => (
    <div className="flex w-full mx-4 items-center justify-between">
      {!dropDownVisible ? <MdMenu size="3rem" /> : <MdClose size="3rem" />}
    </div>
  )

  return (
    // "group" allows for child elements to be affected by hover over a parent element
    // https://tailwindcss.com/docs/pseudo-class-variants#group-hover
    // https://tailwindcss.com/docs/configuring-variants
    <div
      className="group relative inline-block h-full text "
      onClick={() => handleDropDownClick()}
      ref={dropDownRef}
    >
      {/* If no handle is provided, render a visually identical non-link item. Empty string is OK (i.e. Index Page) */}
      {handle != null ? (
        <Link
          to={`${handle}`}
          key={itemKey}
          activeClassName="text-white "
          className={`${navLinkClass}`}
        >
          <div className="">{name}</div>
        </Link>
      ) : (
        <div className={` cursor-pointer ${navLinkClass}   `}>{name}</div>
      )}
      {dropdown && (
        <MobileDropdownMenu
          dropdown={dropdown}
          dropdownWrap={dropdownWrap}
          dropDownVisible={dropDownVisible}
          ref={dropDownRef}
        />
      )}
    </div>
  )
}

export default MobileNav
