import React, { useState } from "react"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MdMenu, MdClose } from "react-icons/md"
import NavbarItem from "./NavbarItem"
import MobileNav from "./MobileNav"
import GatsbyImage from "gatsby-image"
import CommunityLogoLink from "./CommunityLogoLink"

const NavGridContainer = ({ children }) => (
  <div className=" grid grid-flow-col h-full items-center">{children}</div>
)

const NavBar = () => {
  const navbarData = useStaticQuery(graphql`
    query {
      communities: allSanityIndividualCommunityPages(
        sort: { fields: communityName, order: ASC }
      ) {
        edges {
          node {
            communityName
            slug {
              current
            }
            id
          }
        }
      }
      navLogoQuery: allFile(
        filter: { relativePath: { eq: "Shib-HomePageLogo-White.png" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            publicURL
          }
        }
      }

      defaultLogoQuery: sanitySettings {
        logoImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      customPages: allSanityCustomPages(
        filter: { includeInMenu: { eq: true } }
      ) {
        edges {
          node {
            id
            title
            slug {
              current
            }
          }
        }
      }
      departments: allSanityDepartments(
        sort: { fields: departmentName, order: ASC }
      ) {
        edges {
          node {
            showInList
            departmentName
            slug {
              current
            }
            id
          }
        }
      }

      kasLogo: file(relativePath: { eq: "logos/Kasabonika Lake.jpg" }) {
        ...CommunityLogo
      }
      kingfisherLogo: file(relativePath: { eq: "logos/Kingfisher Lake.jpg" }) {
        ...CommunityLogo
      }
      wapekekaLogo: file(relativePath: { eq: "logos/Wapekeka.jpg" }) {
        ...CommunityLogo
      }
      wawakapewinLogo: file(relativePath: { eq: "logos/Wawakapewin.jpg" }) {
        ...CommunityLogo
      }
      wunnuminLogo: file(relativePath: { eq: "logos/Wunnumin Lake.jpg" }) {
        ...CommunityLogo
      }
    }
  `)

  const {
    customPages,
    kasLogo,
    kingfisherLogo,
    wapekekaLogo,
    wawakapewinLogo,
    wunnuminLogo,
  } = navbarData

  const communities = [
    {
      name: "Kasabonika Lake",
      handle: "kasabonika-lake-first-nation",
      logoFluid: kasLogo.childImageSharp.fluid,
    },
    {
      name: "Kingfisher Lake",
      handle: "kingfisher-lake-first-nation",
      logoFluid: kingfisherLogo.childImageSharp.fluid,
    },

    {
      name: "Wapekeka",
      handle: "wapekeka-first-nation",
      logoFluid: wapekekaLogo.childImageSharp.fluid,
    },
    {
      name: "Wawakapewin",
      handle: "wawakapewin-first-nation",
      logoFluid: wawakapewinLogo.childImageSharp.fluid,
    },
    {
      name: "Wunnumin Lake",
      handle: "wunnumin-lake-first-nation",
      logoFluid: wunnuminLogo.childImageSharp.fluid,
    },
  ]

  // place custom page data into a variable in the correctstucture
  let customItems = customPages.edges.map(({ node: customPage }) => {
    return {
      name: customPage.title,
      handle: `/pages/${customPage.slug.current}`,
      key: `${customPage.id}-${customPage.slug.current}`,
    }
  })

  // add custom pages to end of "more" menu
  const dropDownItems = [
    { name: "News", handle: "/news", key: "news" },

    { name: "Calendar", handle: "/calendar", key: "calendar" },
    {
      name: "Media",
      handle: "/media",
      key: "media",
    },
    {
      name: "Room Booking",
      handle: "/roombooking",
      key: "room-booking",
    },
    {
      name: "Outlook Login",
      url: "https://www.office.com/",
      key: "ms365-dropdown",
    },
    {
      name: "Shibogama Mail",
      url: "https://mail.shibogama.on.ca/",
      key: "shibmail-dropdown",
    },
    {
      name: "COVID-19",
      handle: "/covid-19",
      key: "covid19",
    },
  ].concat(customItems)

  // ! added Aug 30, 2021 temporarily until logo with text can be uploaded to Sanity
  const logo = !navbarData.defaultLogoQuery
    ? navbarData.defaultLogoQuery.logoImage.asset
    : navbarData.navLogoQuery.edges[0].node.childImageSharp

  // Links for Mobile Menu
  const navLinks = [
    {
      name: "Home",
      handle: "/",
      key: "home",
    },
    {
      name: "About Us",
      handle: "/about",
      key: "about",
    },
    {
      name: "Contact Us",
      handle: "/contact",
      key: "contact",
    },
    {
      name: "Jobs",
      handle: "/jobs",
      key: "jobs",
    },
    {
      name: "News",
      handle: "/news",
      key: "news",
    },
    {
      name: "Departments",
      handle: "/departments",
      key: "departments",
    },
    {
      name: "Communities",
      handle: "/communities",
      key: "communities",
    },
    {
      name: "Calendar",
      handle: "/calendar",
      key: "calendar",
    },
    {
      name: "Media",
      handle: "/media",
      key: "media",
    },
    {
      name: "COVID-19",
      handle: "/covid-19",
      key: "covid19",
    },
  ].concat(customItems)

  // filter out items not to show, before mapping over
  const departmentLinks = navbarData.departments.edges
    .filter(dept => dept.node.showInList)
    .map(department => {
      const { departmentName, id } = department.node
      return {
        name: departmentName,
        handle: `/departments/${department.node.slug.current}`,
        key: `${departmentName}-${id}`,
      }
    })

  const communityLinks = navbarData.communities.edges.map(community => {
    const { communityName: name, slug, id } = community.node
    return {
      name,
      handle: `/communities/${slug.current}`,
      key: `{${id}-${slug.current}}`,
    }
  })

  // console.log(communityLinks)

  // Mobile Menu Logic
  const [menuVisibility, setMenuVisibility] = useState("hidden")
  const [hamburgerVisible, setHamburgerVisible] = useState(false)
  const visualMenu = "flex flex-col bg-white text-black p-4 lg:hidden"
  const toggleMenu = () => {
    if (menuVisibility === "hidden") {
      setHamburgerVisible(false)
      setMenuVisibility(visualMenu)
    }
    if (menuVisibility === visualMenu) {
      setMenuVisibility("hidden")
      setHamburgerVisible(true)
    }
  }

  return (
    <nav className="shadow">
      {/* Top Layer of Nav Bar */}
      <div className="flex items-end justify-between">
        {/* Mobile Nav */}
        <div className="flex xl:hidden   text-black ">
          <MobileNav key="departments" dropdown={navLinks} />
        </div>

        {/* Desktop Nav */}
        <div className="hidden xl:grid lg:grid-flow-col  gap-x-2 bg-white overflow-visible text-black justify-stretch items-center mt-0 px-4 text-xs lg:text-sm flex-grow rounded-t-3xl ">
          <NavGridContainer>
            <NavbarItem name="Home" handle="/" key="home" />
            <NavbarItem name="About Us" handle="/about" key="about" />
            {/* <NavbarItem name="Contact Us" handle="contact" key="contact" /> */}
            {/* <NavbarItem name="Jobs" handle="jobs" key="jobs" /> */}
            {/* <NavbarItem name="News" handle="news" key="news" /> */}
            <NavbarItem
              name="Our Communities"
              handle="/communities"
              key="communities"
              dropdown={communityLinks}
            />
            <NavbarItem
              name="Departments"
              handle="/departments"
              key="departments"
              dropdown={departmentLinks}
            />

            <NavbarItem
              name="Contact Us"
              handle="/contact"
              key="contact"
              dropdown={[
                {
                  name: "Staff",
                  handle: "/contact/#staff",
                  key: "staff",
                },
                {
                  name: "Current Opportunities",
                  handle: "/jobs",
                  key: "jobs",
                },
              ]}
              dropdownWrap={false}
            />

            <NavbarItem
              name="More"
              key="more"
              dropdown={dropDownItems}
              dropdownWrap={false}
            />
          </NavGridContainer>
        </div>
        {/* LOGO */}
        <div className="text-white ">
          <div className="p-1 pl-3   rounded-bl-2xl">
            <div className="flex justify-between">
              <Link className="" to="/">
                <Img className=" w-56 md:w-72 " fluid={logo.fluid} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Logo Layer of navbar */}
      <CommunityLogoLink communities={communities} />
    </nav>
  )
}

export default NavBar
