import React from "react"
import { Link } from "gatsby"
import DropdownMenu from "./DropdownMenu"

// default to empty strings because I may use it for other purposes (that don't use these props)
const NavbarItem = ({
  handle = null,
  itemKey = "",
  name = "",
  dropdown = null,
  dropdownWrap = false,
  className = "",
}) => {
  const navLinkClass = ` text-shibBlue text-center group-hover:bg-shibBlue group-hover:text-white  xl:text-base h-full grid items-center py-3 uppercase tracking-wide font-bold ${className} `
  // console.log(handle)
  return (
    // "group" allows for child elements to be affected by hover over a parent element
    // https://tailwindcss.com/docs/pseudo-class-variants#group-hover
    // https://tailwindcss.com/docs/configuring-variants
    <div className="group relative inline-block h-full  text ">
      {/* If no handle is provided, render a visually identical non-link item. Empty string is OK (i.e. Index Page) */}
      {handle != null ? (
        <Link
          to={`${handle}`}
          key={itemKey}
          activeClassName="text-white "
          className={navLinkClass}
        >
          <div className="">{name}</div>
        </Link>
      ) : (
        <div className={` cursor-pointer ${navLinkClass}  `}>{name}</div>
      )}
      {dropdown && (
        <DropdownMenu dropdown={dropdown} dropdownWrap={dropdownWrap} />
      )}
    </div>
  )
}

export default NavbarItem
